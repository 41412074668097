import {defineStore} from 'pinia'
import axios from "axios"
import uninterceptedAxiosInstance from "../services/setupUninterceptors";
import {useContractsStore} from '@/stores/contracts';
import _cloneDeep from "lodash/cloneDeep";
import * as Sentry from "@sentry/browser";
import {Storage} from "@ionic/storage";

const getCompanyToStorage = () => {
    const val = localStorage.getItem('_company');
    return val ? val : null;
};
const checkSessionId = () => {
    const val = localStorage.getItem('_sId');
    return val ? val : null;
};
const checkUser = () => {
    const val = localStorage.getItem('_u');
    return val ? JSON.parse(val) : null;
};
const checkErrorMessage = () => {
    const val = localStorage.getItem('eMsg');
    return val ? val : null;
};
const checkOtpEmail = () => {
    const val = localStorage.getItem('_otpe');
    return val ? JSON.parse(val) : null;
};
const checkOtpPhone = () => {
    const val = localStorage.getItem('_otpp');
    return val ? JSON.parse(val) : null;
};

const storageName = 'session';
const storage = new Storage({
    storeName: storageName
});
storage.create();

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
}
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
}


const userTypesAccepted = ['Agency', "Customer", "Lead"];

export const useSessionStore = defineStore('session', {
    state: () => {
        return {
            /** @type String */
            company: getCompanyToStorage(),
            /** @type String */
            sessionId: checkSessionId(),
            /** @type Object */
            user: checkUser(),
            /** @type String */
            errorMessage: checkErrorMessage(),
            /** @type Object */
            otpEmail: checkOtpEmail(),
            /** @type Object */
            otpPhone: checkOtpPhone(),
            /** @type Object */
            feedbackData: {show: false},
            /** @type Boolean */
            loading: false,
            linkUserId: null,
            clientSpec: null, // { "type": "Browser", "appId": "string", "ip": "string", "version": "string" }
            registerUser: {},
            limitModules: [],

            tour: null,
            windowWidth: null,
            windowOrientation: null,
            module: []
        }
    },
    getters: {
        getClientSpec: (state) => state.clientSpec,
        getCompany: (state) => state.company,
        getSessionId: (state) => state.sessionId,
        isLoggedIn: (state) => state.user !== null && state.user?.id !== '',
        getErrorMessage: (state) => state.errorMessage,
        getFeedbackData: (state) => state.feedbackData,
        getLinkUserId: (state) => state.linkUserId,
        getWorkflowIdOptEmail: (state) => state.otpEmail.workflowId,
        getWorkflowIdOptPhone: (state) => state.otpPhone.workflowId,
        getRegisterUser: (state) => state.registerUser,
        getTour: (state) => state.tour,
        getWindowWidth: (state) => state.windowWidth,
        getWindowOrientation: (state) => state.windowOrientation,
        getIsMobile: (state) => state.windowWidth < 992,
    },
    actions: {
        async setCompany(company, forceNewSession = false) {
            document.body.classList.add(company.toLowerCase());
            this.company = company.toLowerCase();
            if(this.company === 'sgr')
            {
                document.documentElement.style.setProperty('--ion-color-primary', '#009FE3');
                document.documentElement.style.setProperty('--ion-color-secondary', '#00205B');
            }
            else if(this.company === 'ast')
            {
                document.documentElement.style.setProperty('--ion-color-primary', '#00205b');
                document.documentElement.style.setProperty('--ion-color-secondary', '#009FE3');
            }
            else if(this.company === 'sga')
            {
                document.documentElement.style.setProperty('--ion-color-primary', '#A30000');
                document.documentElement.style.setProperty('--ion-color-secondary', '#620000');
            }
            else if(this.company === 'mes')
            {
                document.documentElement.style.setProperty('--ion-color-primary', '#DC2122');
                document.documentElement.style.setProperty('--ion-color-secondary', '#287CBE');
            }
            localStorage.setItem('_company', this.company);
            if(forceNewSession) await useSessionStore().createSession();
        },

        async setClientSpec(obj) {
            this.clientSpec = obj;
        },

        async createSession(extra = {}, saveSessionId = true, useUninterceptedAxiosInstance = false) {
            if(this.company !== null )
            {
                let params = {
                    "createNewSessionRequest": {
                        "company": ( this.company ? this.company.toUpperCase() : null ),
                        "locale": "it",
                    }
                };

                if(this.clientSpec)
                {
                    params.createNewSessionRequest.clientSpec = this.clientSpec;
                }
                params.createNewSessionRequest = {...params.createNewSessionRequest, ...extra};
                try {
                    let axClient = ( useUninterceptedAxiosInstance ? uninterceptedAxiosInstance : axios);
                    const {data} = await axClient.post('/services/rest/portalapi/Core/v1/sessions', params);
                    if(saveSessionId) {
                        this.sessionId = data.createNewSessionResponse.session.id;
                        localStorage.setItem('_sId', this.sessionId);
                        await this.modules();
                    }
                    else
                    {
                        this.sessionId = data.createNewSessionResponse.session.id;
                        await this.modules();
                    }

                    return {success: true, data: data.createNewSessionResponse.session};
                } catch (error) {
                    if (error.response) {
                        console.log(error.response);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                }
            }
            return {success: false};
        },

        async getSession(useUninterceptedAxiosInstance = false) {
            try {
                let axClient = ( useUninterceptedAxiosInstance ? uninterceptedAxiosInstance : axios);
                const {data} = await axClient.get('/services/rest/portalapi/Core/v1/sessions/' + this.sessionId);
                if (data.session.user.id) {
                    const contractsStore = useContractsStore();
                    contractsStore.$patch({
                        contracts: data.session.user.contracts.contract
                    })

                    this.user = data.session.user;
                    localStorage.setItem('_u', JSON.stringify(data.session.user));
                    return {success: true, data: data.session};
                } else
                    return {success: false, data: 'error_message'};
            } catch (error) {
                return {success: false, data: 'error_message'};
            }
        },

        async clearSession(clearSessionStorage = true) {
            this.user = null;
            this.sessionId = null;
            localStorage.removeItem('_u');
            localStorage.removeItem('_sId');
            localStorage.removeItem('_cId');
            if( clearSessionStorage ) sessionStorage.clear();
            const contractsStore = useContractsStore();
            contractsStore.setCustomer(null);
        },

        async logIn(username, password) {
            const params = {
                "doLoginRequest": {
                    username,
                    password,
                }
            };
            try {
                const {data} = await axios.post('/services/rest/portalapi/Core/v1/user/login', params);
                let user = data.doLoginResponse.user;
                if (user.status === 'Active' && userTypesAccepted.includes(user.type)) {
                    const contractsStore = useContractsStore();
                    contractsStore.$patch({
                        contracts: user.contracts.contract
                    })
                    Sentry.setUser({ id: user.key });
                    this.user = _cloneDeep(user);
                    localStorage.setItem('_u', JSON.stringify(user));
                    return {success: true, data: user};
                }

                return {success: false, data: 'error_message'};

            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async logOutPartial(clearCustomer = false) {
            this.user = null;
            this.sessionId = null;
            localStorage.removeItem('_u');
            localStorage.removeItem('_sId');
            sessionStorage.clear();

            if(clearCustomer){
                localStorage.removeItem('_cId');
                localStorage.removeItem('_company');

                const contractsStore = useContractsStore();
                contractsStore.setCustomer(null);
            }
        },

        async logOut(clearSessionStorage = true) {
            const params = {
                "doLogoutRequest": {}
            };
            try {
                const {data} = await axios.delete('/services/rest/portalapi/Core/v1/user/logout', {params});
                return {success: true, data: data};

            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            } finally {
                this.clearSession(clearSessionStorage);
            }
        },

        async setLinkUserId(id) {
            this.linkUserId = id;
        },

        async getUser(id) {
            try {
                const {data} = await axios.get('/services/rest/portalapi/Core/v1/users/' + id);
                if (data.getUserResponse.user.status === 'Active' && userTypesAccepted.includes(data.getUserResponse.user.type))
                    return {success: true, data: data.getUserResponse.user};

                return {success: false, data: 'error_message'};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async getUserByContractKey(contractKey) {
            try {
                const {data} = await axios.get('/services/rest/portalapi/Core/v1/users/?contractKey=' + contractKey);

                return {success: true, data: data.findUsersResponse.users.user};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async setRegisterUser(data) {
            const dataUser = _cloneDeep(data)
            const user = _cloneDeep(this.registerUser)
            if (typeof data.accountReg !== "undefined") {
                const accountReg = _cloneDeep(this.registerUser.accountReg)
                dataUser.accountReg = {...accountReg, ...data.accountReg};
            }
            if (typeof data.accountRegUpd !== "undefined") {
                dataUser.accountRegUpd = _cloneDeep(data.accountRegUpd);
            }
            this.registerUser = {...user, ...dataUser};
            return {success: true, data: this.registerUser};
        },

        setSessionId(id) {
            this.sessionId = id;
        },

        async createUser(payload) {
            const params = {
                "createNewUserRequest": payload
            };
            if (!payload.user.type) payload.user.type = 'Customer';

            try {
                const {data} = await axios.post('/services/rest/portalapi/Core/v1/users', params);
                const id = data.createNewUserResponse.id;
                const user = data.createNewUserResponse.user;
                return {success: id > 0, data: user};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async associateContractToUser(payload) {
            const params = {
                "associateContratToUserRequest": payload
            };
            try {
                const {data} = await axios.post('/services/rest/portalapi/Wfm/v1/associate-contract-to-user/start', params);
                const id = data.associateContratToUserResponse.workflowId;
                return {success: id > 0};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },


        async workflowTaskExecute(payload) {
            const params = {
                "executeTaskRequest": payload
            };
            try {
                await axios.post('/services/rest/portalapi/Wfm/v1/workflow/task-execute', params);
                return {success: true};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async userValidate(user) {
            const params = {
                "validateUserRequest": {
                    user
                }
            };
            if (!user.type) user.type = 'Customer';
            try {
                await axios.post('/services/rest/portalapi/Core/v1/users/validate', params);
                return {success: true};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '').errori};
            }
        },

        async setErrorMessage(msg) {
            this.errorMessage = msg;
            if (msg === null)
                localStorage.removeItem('eMsg');
            else
                localStorage.setItem('eMsg', msg);
        },

        async setFeedbackData(data) {
            this.feedbackData = {...{show: true}, ...data};
        },
        async clearFeedbackData() {
            this.feedbackData = {show: false};
        },
        async setLoading(val) {
            this.loading = val;
        },
        async setWindowWidth(val) {
            this.windowWidth = val;
        },
        async setWindowOrientation(val) {
            this.windowOrientation = val;
        },

        async getLink(linkId, hashCode) {
            try {
                const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Core/v1/links/' + linkId + '?hashCode=' + hashCode);
                const link = data.getLinkResponse.link;
                return {success: true, data: link};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async getConfProperties(propertyName) {
            try {
                if(!this.sessionId && !this.company)
                {
                    let params = {
                        "createNewSessionRequest": {
                            "company": "SGR",
                            "locale": "it",
                        }
                    };
                    params.createNewSessionRequest = {...params.createNewSessionRequest};
                    const {data} = await axios.post('/services/rest/portalapi/Core/v1/sessions', params);
                    this.sessionId = data.createNewSessionResponse.session.id;
                }

                const {data} = await uninterceptedAxiosInstance.get('/services/rest/portalapi/Core/v1/confProperties?propertyName=' + propertyName);
                if(typeof data.findConfPropertiesResponse !== 'undefined'){
                    const result = data.findConfPropertiesResponse.properties?.property;
                    return {success: true, data: result};
                }

                return {success: false };
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async passwordReset(payload) {
            const params = {
                "passwordResetRequest": payload
            };
            try {
                const {data} = await axios.post('/services/rest/portalapi/Wfm/v1/password-reset/start', params);
                return {success: data.passwordResetResponse.queued};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async changePassword(password, userId = null) {
            const id = userId === null ? this.user.id : userId;
            const params = {
                "updateUserRequest": {
                    "user": {
                        id,
                        password
                    }
                }
            };
            try {
                await axios.put('/services/rest/portalapi/Core/v1/users', params);
                return {success: true};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async changeEmail(email) {
            email = email.trim();
            const contractsStore = useContractsStore();
            const params = {
                "accountDataChangeRequest": {
                    "customer": {
                        id: contractsStore.getCurrentCustomer.id,
                        account: {
                            email
                        }
                    }
                }
            };
            try {
                const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/accountData', params);
                const id = data.accountDataChangeResponse.requestId;
                if (id > 0) localStorage.removeItem('_otpe');
                return {success: id > 0};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async emailCheck(email, userKey = null) {
            //const key = userKey === null ? this.user.accountReg.key : userKey;
            localStorage.removeItem('_otpe');
            email = email.trim();
            let otpEmail = {
                email: email,
                workflowId: 0
            };
            this.otpEmail = otpEmail;
            const params = {
                "emailCheckRequest": {
                    email,
                    company: this.company.toUpperCase(),
                    accountNumber: userKey,
                }
            };
            try {
                const {data} = await axios.post('/services/rest/bpm/v1/email-check', params);
                const workflowId = data.emailCheckResponse.workflowId;
                if (workflowId > 0) otpEmail.workflowId = workflowId;
                this.otpEmail = otpEmail;
                localStorage.setItem('_otpe', JSON.stringify(otpEmail));
                return {success: workflowId > 0, data: otpEmail};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async changePhone(phoneNum, isMobile = true) {
            phoneNum = phoneNum.trim();
            const contractsStore = useContractsStore();
            var params = {};
            var accountData = {
                phoneMobile: phoneNum
            };

            if(!isMobile){
                accountData = {
                    phoneHome: phoneNum
                };
            }

            params = {
                "accountDataChangeRequest": {
                    "customer": {
                        id: contractsStore.getCurrentCustomer.id,
                        account: accountData
                    }
                }
            };

            try {
                const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/accountData', params);
                const id = data.accountDataChangeResponse.requestId;
                if (id > 0) localStorage.removeItem('_otpp');
                return {success: id > 0};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async phoneCheck(phoneNum, userKey = null) {
            phoneNum = phoneNum.trim();
            //const key = userKey === null ? this.user.accountReg.key : userKey;
            localStorage.removeItem('_otpp');
            let otpPhone = {
                phoneNum: phoneNum,
                workflowId: 0
            };
            this.otpPhone = otpPhone;
            const params = {
                "phoneCheckRequest": {
                    phoneNum,
                    company: this.company.toUpperCase(),
                    accountNumber: userKey,
                }
            };
            try {
                const {data} = await axios.post('/services/rest/bpm/v1/phone-check', params);
                const workflowId = data.phoneCheckResponse.workflowId;
                if (workflowId > 0) otpPhone.workflowId = workflowId;
                this.otpPhone = otpPhone;
                localStorage.setItem('_otpp', JSON.stringify(otpPhone));
                return {success: workflowId > 0, data: otpPhone};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async otpVerification(workflowId, code) {
            const params = {
                "otpVerificationRequest": {
                    workflowId,
                    code,
                }
            };
            try {
                const {data} = await axios.post('/services/rest/bpm/v1/otp-verification', params);
                const matches = data.otpVerificationResponse.matches;
                return {success: matches};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async changePrivacy(privacyCompany, privacyOthers, key = null) {
            const contractsStore = useContractsStore();
            const params = {
                accountDataChangeRequest: {
                    customer: {
                        privacyCompany,
                        privacyOthers,
                    }
                }
            };
            if (key === null) {
                params.accountDataChangeRequest.customer.id = contractsStore.getCurrentCustomer.id
            } else {
                params.accountDataChangeRequest.customer.key = key
            }
            try {
                const {data} = await axios.post('/services/rest/portalapi/Erp/v1/requests/accountData', params);
                const id = data.accountDataChangeResponse.requestId;
                return {success: id > 0};
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async setUserOptions(userOptions) {
            const self = this;
            try {
                if(typeof self.user !== 'undefined' && this.isLoggedIn)
                {
                    const id = self.user.id;
                    const {data} = await axios.get('/services/rest/portalapi/Core/v1/users/' + id);
                    let user = data.getUserResponse.user;
                    if (user.status === 'Active') {
                        this.user.userOptions = user.userOptions;
                        if (!this.user.userOptions) self.user.userOptions = {};
                        else self.user.userOptions = JSON.parse(self.user.userOptions);
                        var obj = Object.assign({}, self.user.userOptions, userOptions);
                        userOptions = JSON.stringify(obj);
                        const params = {
                            "updateUserRequest": {
                                "user": {
                                    id,
                                    userOptions
                                }
                            }
                        };

                        await axios.put('/services/rest/portalapi/Core/v1/users', params);
                        this.getUser(id).then((res) => {
                            if (res.success) {
                                self.user = res.data;
                                localStorage.setItem('_u', JSON.stringify(res.data));
                            }
                        })
                        return {success: true};
                    }
                }
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }

            return {success: false};

        },

        async setUserNotificationPush(tech, commercial) {
            const self = this;
            try {
                if(typeof self.user !== 'undefined' && this.isLoggedIn)
                {
                    const id = self.user.id;
                    const {data} = await axios.get('/services/rest/portalapi/Core/v1/users/' + id);
                    let user = data.getUserResponse.user;
                    if (user.status === 'Active') {
                        const params = {
                            "updateUserRequest": {
                                "user": {
                                    id,
                                    receiveCommercialNotifications: commercial,
                                    receiveTechNotifications: tech,
                                }
                            }
                        };
                        await axios.put('/services/rest/portalapi/Core/v1/users', params);
                        this.getUser(id).then((res) => {
                            if (res.success) {
                                self.user = res.data;
                                localStorage.setItem('_u', JSON.stringify(res.data));
                            }
                        })
                        return {success: true};
                    }
                }
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }

            return {success: false};

        },

        async devices(fbPushId) {
            const params = {
                "registerDeviceRequest": {
                    "fbPushId" : fbPushId
                }
            };
            try {
                await axios.post('/services/rest/portalapi/Core/v1/devices', params);
                return {success: true };
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async checkFiscalCode(value) {
            const params = {
                "checkFiscalDataRequest": value
            };
            try {
                await axios.post('/services/rest/portalapi/Core/v1/check-fiscal-data', params);
                return {success: true };
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },

        async modules(cached = false) {

            const k = 'modules' + this.company;
            const myCipherK = k;
            const myCipherR = k + 'R';
            const myCipher = cipher(myCipherK);
            if(cached)
            {
                var v = await storage.get(myCipher(storageName));
                if(v !== null){
                    const myCipherD = decipher(myCipherR);
                    return JSON.parse(myCipherD(v));
                }
            }

            const params = {};
            try {

                if(!this.sessionId) return {success: false, data: this.module };
                if(cached && this.module && this.module.length > 0) return {success: true, data: this.module };

                const {data} = await axios.get('/services/rest/portalapi/Core/v1/modules', params);
                if (typeof data.findModulesResponse.modules.module !== "undefined") this.$patch({module: data.findModulesResponse.modules.module});

                const _return = {success: true, data: this.module };
                const myCipher2 = cipher(myCipherR);
                storage.set(myCipher(storageName), myCipher2(JSON.stringify(_return)));
                return _return;
            } catch (error) {
                return {success: false, data: error.message, errors: (error.response?.data ? error.response.data : '')};
            }
        },
    },
})
